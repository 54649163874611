import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Nikita Vasyliev" subtitle="PA to the Director, Alumni and Friends Association Liaison Officer " mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "570px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAXAQEBAQEAAAAAAAAAAAAAAAABAwAC/9oADAMBAAIQAxAAAAGLvVSukAdD1FZqLNKAn//EAB0QAAEEAgMAAAAAAAAAAAAAAAIAAQMRBBATIzL/2gAIAQEAAQUC2PrKYeJQxMQuLEJ49FE/Xatf/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8BI//EABkRAQACAwAAAAAAAAAAAAAAAAEAERASMf/aAAgBAgEBPwFaaw9mzP/EABwQAAIBBQEAAAAAAAAAAAAAAAABEAIREiExIv/aAAgBAQAGPwKULi3GVRi1o88KbT//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEQITFhQVH/2gAIAQEAAT8hiJ0xXfyzsEwNxRjvj8INteo30+pfQoNYahc//9oADAMBAAIAAwAAABDDyI//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/ECheDDTf/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQEVH/2gAIAQIBAT8QxOqS6hL/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhQRAxUWHR/9oACAEBAAE/EO4OKA9car2tKvcoOkCtcZ/eFtiVLqKN/YWMs0s36MzSqXsj5CYiGr6VcFTK5C0vE//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg",
              "srcSet": ["/static/8b2d4d43364c2dfe2aff04473175a926/f93b5/01.jpg 300w", "/static/8b2d4d43364c2dfe2aff04473175a926/882a4/01.jpg 570w"],
              "sizes": "(max-width: 570px) 100vw, 570px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <p>{`Despite the pandemic, the 20 - 21 school year was a success for the PSI Alumni Association. We are pleased to share with you some of our accomplishments and ongoing work.`}</p>
    <p>{`In partnership with the School Leadership Team members and support from the School Director, we continue to work diligently to offer a variety of thoughtful opportunities to ensure that connection and relationship with PSI Alumni remain strong throughout the COVID-19 pandemic and the forthcoming new normal.`}</p>
    <h4 {...{
      "id": "focus-on-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#focus-on-data",
        "aria-label": "focus on data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Focus on Data`}</h4>
    <p>{`This school year we continued to improve and enhance our current alumni database.`}</p>
    <p>{`For this purpose, we have developed a new alumni survey targeting the current DP, and all of former PSI students. We reshared this survey with our recent graduates and received a very positive result with an increased response rate in comparison to the previous school year, 2019 - 20 - 48%.`}</p>
    <p>{`We have planned to share this survey with a wider community of our former students which will help us to restructure our data and enhance our relationship with all PSI alumni.`}</p>
    <p>{`We also reviewed the entry points for the data and web presence of the Alumni Association across various social media platforms. For the next school year, we are going to build a communication plan and enhance our current communication channels with alumni.`}</p>
    <h4 {...{
      "id": "alumni-engagement-with-psi-students-life-after-psi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#alumni-engagement-with-psi-students-life-after-psi",
        "aria-label": "alumni engagement with psi students life after psi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alumni engagement with PSI students: Life after PSI`}</h4>
    <p>{`On 18 March we hosted an Alumni panel Q&A session for our IB DP students. Six PSI alumni joined us from across the globe: the US, UK, Netherlands and Singapore.`}</p>
    <p>{`It was fascinating to hear their personal experiences and perspectives on life at and after PSI. Their stories were uplifting and the Grade 11 and 12 students in attendance were fortunate to gain much useful information and advice.`}</p>
    <h4 {...{
      "id": "zoom-a-thon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#zoom-a-thon",
        "aria-label": "zoom a thon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zoom-A-Thon`}</h4>
    <p>{`We were fortunate to seek and receive tremendous support from one of our alumni, Nikita Taratorin, who hosted a fabulous & successful Zoom-A-Thon event that was organised by the PSI CAS team (more information about this fantastic event can be found in the CAS section).`}</p>
    <p>{`We are grateful and honoured to have alumni, such as Nikita, that support the school community and help us in promoting empathy and set an amazing example for our current students.`}</p>
    <h4 {...{
      "id": "building-connections-with-the-ceesa-region",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#building-connections-with-the-ceesa-region",
        "aria-label": "building connections with the ceesa region permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building connections with the CEESA region`}</h4>
    <p>{`As many of you know, PSI is a proud member of the Central and Eastern European Schools Association (CEESA) region. Over the course of this school year, we held meetings with our colleagues from various international schools.`}</p>
    <p>{`Close collaboration and open dialogue help us to create and learn from the diverse experiences we have. We raised issues related to the COVID-19 pandemic and its impact on the organisation of the events, students engagement and data collection.`}</p>
    <p>{`We are aiming to continue our dialogue and support each other in the future. It was a true pleasure to learn and get to know more about the successful stories of Alumni Associations from the American International School of Bucharest (AISB) and the American International School of Vienna (AISV).`}</p>
    <h4 {...{
      "id": "thinking-of-the-future",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#thinking-of-the-future",
        "aria-label": "thinking of the future permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thinking of the future`}</h4>
    <p>{`We are sad that as yet we are not able to reunite in the ways we used to do but certainly, we are hopeful that the ongoing global vaccination campaign and our research will help us to find and organise new opportunities to reconnect alumni to each other and to the PSI traditions we all hold so dear.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      